@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'AppleSDGothicNeo';
    font-style: normal;
    font-weight: 500;
    src: url('/public/fonts/AppleSDGothicNeo-Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'AppleSDGothicNeo';
    font-style: bold;
    font-weight: 700;
    src: url('/public/fonts/AppleSDGothicNeo-Bold.ttf') format('truetype');
}

html {
    font-family: 'helvetica', 'AppleSDGothicNeo', Sans-serif;
}

body {
    @apply overflow-x-hidden max-w-[100vw] min-h-screen antialiased text-gray-950;
}

.scrollbar::-webkit-scrollbar {
    @apply w-2;
}

.scrollbar::-webkit-scrollbar-thumb {
    @apply bg-gray-200 rounded-xl bg-clip-padding shadow-md;
}

.scrollbar::-webkit-scrollbar-track {
    @apply bg-white rounded-xl shadow-md;
}